import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import userIcon from './userIcon.png';  // Make sure to have a user icon image in your project

const UserCountBadge = ({ count }) => {
    return (
        <Box position="relative" display="inline-block" width="50px" height="50px">
            <Image src={userIcon} alt="User Icon" width="100%" height="100%" />
            <Text
                position="absolute"
                top="0"
                right="0"
                background="red.500"
                color="white"
                borderRadius="full"
                px="2"
                fontSize="xs"
                fontWeight="bold"
            >
                {count}
            </Text>
        </Box>
    );
};

export default UserCountBadge;

