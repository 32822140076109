import React from 'react';
import { Box, Heading, Text, Stack, Icon, Flex, VStack } from '@chakra-ui/react';
import { FaGavel, FaTrophy, FaClock, FaWallet, FaDollarSign, FaShieldAlt, FaExclamationCircle } from 'react-icons/fa';
import { Pie } from 'react-chartjs-2';
import Layout from './components/Layout';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-dracula';
import 'ace-builds/src-noconflict/mode-python';
import { Chart, ArcElement } from 'chart.js';

// Register the ArcElement for Pie charts
Chart.register(ArcElement);

const rules = [
    {
        title: 'Tournament Frequency',
        icon: FaClock,
        description: 'A new tournament starts every hour, remains open for one hour for player registration, and runs for an hour or until all challenges are solved.',
    },
    {
        title: 'Challenges',
        icon: FaGavel,
        description: 'Each tournament features a collection of challenges that can be solved using Python. Challenges can be solved in any order and they range in complexity, technical domains, and the creativity required.',
    },
    {
        title: 'Entry Cost',
        icon: FaDollarSign,
        description: 'To participate in a tournament, users must pay an entry fee. This fee contributes to the tournament\'s reward pool.',
    },
    {
        title: 'Scoring and Rewards',
        icon: FaTrophy,
        description: 'When a user solves a challenge, the challenge is closed and they earn a portion of the reward pool.',
    },
    {
        title: 'Mandatory Focus',
        icon: FaExclamationCircle,
        description: 'Users must stay focused on the tournament tab. Using any other program will result in the user leaving the tournament and forfeiting unclaimed rewards.',
    },
    {
        title: 'Fair Play',
        icon: FaShieldAlt,
        description: 'Any attempt to cheat will result in disqualification and potential bans from future tournaments.',
    },
    {
        title: 'Transactions',
        icon: FaWallet,
        description: 'All transactions are made on the Polygon chain using MATIC.',
    },
];

const entryFeeData = {
    labels: ['Reward Pool (97%)', 'Platform (3%)'],
    datasets: [
        {
            data: [97, 3],
            backgroundColor: ['#4caf50', '#ff6384'],
            hoverBackgroundColor: ['#66bb6a', '#ff6384'],
        },
    ],
};

const rewardPoolData = {
    labels: Array(10).fill('Challenge 10%'),
    datasets: [
        {
            data: Array(10).fill(10),
            backgroundColor: [
                '#36a2eb',
                '#ffcd56',
                '#4bc0c0',
                '#9966ff',
                '#ff6384',
                '#4caf50',
                '#ff9f40',
                '#c9cbcf',
                '#ff4567',
                '#ffd700',
            ],
            hoverBackgroundColor: [
                '#36a2eb',
                '#ffcd56',
                '#4bc0c0',
                '#9966ff',
                '#ff6384',
                '#4caf50',
                '#ff9f40',
                '#c9cbcf',
                '#ff4567',
                '#ffd700',
            ],
        },
    ],
};

const RulesetPage = () => {
    return (
        <Layout>
            <Box maxW="800px" mx="auto" p={4} position="relative">
                <Heading as="h1" mb={6} textAlign="center">
                    Tournament Ruleset
                </Heading>

                {/* Rules List */}
                <Stack spacing={6}>
                    {rules.map((rule, index) => (
                        <Box
                            key={index}
                            p={4}
                            borderRadius="md"
                            bg={index % 2 === 0 ? 'teal.50' : 'gray.50'}
                            shadow="md"
                            position="relative"
                        >
                            <Stack direction="row" align="center">
                                <Icon as={rule.icon} boxSize={6} color="teal.500" />
                                <Text fontWeight="bold" fontSize="lg">{rule.title}</Text>
                            </Stack>
                            <Text mt={2}>{rule.description}</Text>
                        </Box>
                    ))}
                </Stack>

                {/* New Section: Prize Pool Allocation */}
                <Flex justifyContent="space-between" mt={10} p={6} bg="white" borderRadius="lg" shadow="lg">
                    {/* Left: Entry Fees Allocation */}
                    <Box flex={1} textAlign="center">
                        <Heading as="h2" size="md" mb={4}>
                            Entry Fees Allocation
                        </Heading>
                        <Pie data={entryFeeData} />
                        <Text mt={4} fontSize="lg" fontWeight="bold" color="green.500">97% Prize Pool</Text>
                    </Box>

                    {/* Right: Reward Pool Allocation */}
                    <Box flex={1} textAlign="center" position="relative">
                        <Heading as="h2" size="md" mb={4}>
                            Reward Pool Allocation
                        </Heading>
                        <Pie data={rewardPoolData} />
                        <Text
                            position="absolute"
                            top="35px"
                            right="-15px"
                            fontSize="sm"
                            fontWeight="bold"
                            color="gray.600"
                        >
                            10% per challenge
                        </Text>
                    </Box>
                </Flex>

                {/* New Section: Reward Funds */}
                <Box mt={10} p={6} bg="white" borderRadius="lg" shadow="lg">
                    <Heading as="h2" size="md" mb={4} textAlign="center">
                        Reward Funds
                    </Heading>
                    <Text mb={4}>
                        The rewards for each tournament come from two sources:
                    </Text>
                    <Text>
                        <strong>Ticket Purchases:</strong> 97% of the MATIC paid by people buying tickets that round goes back into the prize pools.
                    </Text>
                    <Text mb={4}>
                        <strong>Reserve Pool:</strong> The remaining 3%, along with unclaimed prizes, go towards the reserve pool. Current reserve funds are available below.
                    </Text>
                    {/* Reserve Funds Box */}
                    <Box p={4} bg="gray.100" borderRadius="md" textAlign="center">
                        <Text fontWeight="bold" fontSize="xl">Reserve Pool: 6410.8506
            MATIC</Text>
                    </Box>
                </Box>

                {/* Sample Challenge Section */}
                <Box mt={10} textAlign="center">
                    <Heading as="h2" size="md" mb={4}>
                        Sample Challenge
                    </Heading>
                    <Text>
                        Help Batman calculate the Batmobile's top speed when combining two speed components using modular arithmetic.
                    </Text>
                    <AceEditor
                        mode="python"
                        theme="dracula"
                        name="sample-editor"
                        value={`def batman_batmobile_speed(a, b, mod):
    # example_input: 98765, 43210, 500
    # expected_output: 50
    
    # your code goes here
    
    pass`}
                        editorProps={{ $blockScrolling: true }}
                        width="100%"
                        height="300px"
                    />
                </Box>
            </Box>
        </Layout>
    );
};

export default RulesetPage;
