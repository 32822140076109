import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SimpleGrid, Box, Text, Flex, Image, VStack, HStack, Icon } from '@chakra-ui/react';
import { FaGift, FaUsers, FaTrophy } from 'react-icons/fa'; // Import icons for trophies and tournaments
import Layout from './components/Layout';
import LoadingPopup from './components/LoadingPopup';
import ErrorPopup from './components/ErrorPopup';
import RewardPopup from './components/RewardPopup'; // Import the RewardPopup component
import RewardCard from './RewardCard'; // Import the RewardCard component

const RewardPage = () => {
    const [rewards, setRewards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isCollecting, setIsCollecting] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showRewardPopup, setShowRewardPopup] = useState(false); // Popup state for successful reward collection
    const [userInfo, setUserInfo] = useState(null); // For storing trophies and tournaments info

    // Dynamic backend host for API requests based on environment
    const backendHost = window.location.hostname === "localhost" ? "http://localhost:8000" : window.location.origin;

    // Fetch rewards from the backend
    const fetchRewards = async () => {
        try {
            const token = localStorage.getItem('accessToken'); // Fetch the token from localStorage
            const config = {}; // Default config for axios request

            if (token) {
                // If token exists, include Authorization header
                config.headers = {
                    Authorization: `Bearer ${token}`,
                };
            }

            const response = await axios.get(`${backendHost}/api/rewards/`, config);

            const rewardsData = response.data.rewards || response.data;
            const userInfoData = response.data.user_info || null;

            setRewards(rewardsData);
            setUserInfo(userInfoData);
            setLoading(false);
        } catch (error) {
            setErrorMessage('Failed to load rewards');
            setLoading(false);
            setShowErrorPopup(true);
        }
    };

    useEffect(() => {
        fetchRewards();
    }, []);

    // Handle reward collection
    const handleCollectReward = async (rewardId) => {
        try {
            const token = localStorage.getItem('accessToken');
            setIsCollecting(true);

            // Correct API endpoint for collecting a reward with its ID
            const response = await axios.post(`${backendHost}/api/rewards/collect/${rewardId}/`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`, // Send token in request headers
                },
            });

            console.log('Reward collected:', response.data);
            setIsCollecting(false);

            // Show the reward collection popup
            setShowRewardPopup(true);

            // Update the collected status of the reward in the UI
            setRewards((prevRewards) =>
                prevRewards.map((reward) =>
                    reward.id === rewardId
                        ? { ...reward, hasCollected: true }
                        : reward
                )
            );
        } catch (error) {
            setErrorMessage('Failed to collect reward');
            setIsCollecting(false);
            setShowErrorPopup(true);
        }
    };

    if (loading) return <Text>Loading...</Text>;

    return (
        <Layout>
            <Box maxW="1000px" mx="auto" p={4} position="relative">
                {/* Display user info (trophies and tournaments) if available */}
                {userInfo && (
                    <Box
                        position="absolute"
                        top="-24px"
                        right="10px"
                        bg="gray.100"
                        p={4}
                        borderRadius="md"
                        shadow="lg"
                        textAlign="center"
                    >
                        <HStack>
                            {/* Trophies */}
                            <Icon as={FaTrophy} boxSize={5} color="orange.500" />
                            <Text fontSize="lg" fontWeight="bold" color="teal.600">
                                {userInfo.total_trophies}
                            </Text>

                            {/* Tournaments */}
                            <Icon as={FaUsers} boxSize={5} color="blue.500" />
                            <Text fontSize="lg" fontWeight="bold" color="teal.600">
                                {userInfo.total_tournaments}
                            </Text>
                        </HStack>
                    </Box>
                )}

                <Text fontSize="2xl" mb="4" fontWeight="bold" color="teal" textAlign="center">
                    Rewards
                </Text>

                <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
                    {rewards.map((reward, index) => (
                        <RewardCard
                            key={reward.id}
                            reward={{ ...reward, index }} // Pass reward data and index for styling
                            onCollect={handleCollectReward}  // Pass the handleCollectReward function
                            isCollecting={isCollecting}
                        />
                    ))}
                </SimpleGrid>

                <Flex justifyContent="center" mt="8">
                    <Image src="/comingsooncat.png" alt="Coming Soon" boxSize="300px" />
                </Flex>
            </Box>

            {isCollecting && <LoadingPopup message="Collecting Reward..." />}
            {showErrorPopup && (
                <ErrorPopup
                    message={errorMessage}
                    onClose={() => setShowErrorPopup(false)}
                />
            )}
            {/* Reward collection popup */}
            {showRewardPopup && (
                <RewardPopup
                    message="Congratulations, you collected a reward!"
                    onClose={() => setShowRewardPopup(false)}
                />
            )}
        </Layout>
    );
};

export default RewardPage;
