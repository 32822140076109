// src/components/Layout.js or src/layouts/Layout.js

import React from 'react';
import { Box } from '@chakra-ui/react';

const Layout = ({ children }) => {
    return (
        <Box
            p="6"
            bgGradient="linear(to-b, purple.100, purple.900)" // Gradient from white to dark purple
            minH="100vh"
        >
            {children}
        </Box>
    );
};

export default Layout;
