import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import Layout from './components/Layout';

const WalletLoginPage = () => {
    const [address, setAddress] = useState(null);
    const [message, setMessage] = useState('');
    const [signature, setSignature] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            // Verify the token with the backend before redirecting
            verifyToken(token);
        }

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', handleAccountsChanged);
        }
    }, [navigate]);

    const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
            setAddress(null);
        } else {
            setAddress(accounts[0]);
        }
    };

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                setAddress(accounts[0]);
            } catch (error) {
                console.error('Error connecting to wallet:', error);
            }
        } else {
            alert('MetaMask not detected. Please install MetaMask to use this feature.');
        }
    };

    const signMessage = async () => {
        if (!address) {
            alert('Please connect your wallet first.');
            return;
        }

        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const message = `Sign this message to authenticate with your wallet. Wallet address: ${address}`;

        try {
            const signature = await signer.signMessage(message);
            setSignature(signature);
            await authenticateWithServer(address, signature);
        } catch (error) {
            console.error('Error signing message:', error);
        }
    };

    const authenticateWithServer = async (address, signature) => {
        try {
            // Determine the correct base URL
            const baseURL = window.location.protocol + "//" + window.location.host;

            const response = await fetch(`${baseURL}/api/auth/wallet-login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ address, signature }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('accessToken', data.access); // Store the JWT token
                localStorage.setItem('refreshToken', data.refresh);
                setMessage('Authentication successful!');
                navigate('/profile');  // Redirect to profile page after successful authentication
            } else {
                setMessage('Authentication failed. Please try again.');
            }
        } catch (error) {
            console.error('Error authenticating with server:', error);
            setMessage('Error authenticating. Please try again.');
        }
    };


    const verifyToken = async (token) => {
        try {
            // Determine the correct base URL
            const baseURL = window.location.protocol + "//" + window.location.host;

            const response = await fetch(`${baseURL}/api/auth/check-auth/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                navigate('/profile');  // Redirect to profile page if the token is valid
            } else {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
            }
        } catch (error) {
            console.error('Error verifying token:', error);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        }
    };


    return (
        <Layout>
        <div>
            <h1>Wallet Login</h1>
            {address ? (
                <div>
                    <p>Connected wallet: {address}</p>
                    <button onClick={signMessage}>Sign Message</button>
                </div>
            ) : (
                <button onClick={connectWallet}>Connect Wallet</button>
            )}
            {message && <p>{message}</p>}
        </div>
        </Layout>
    );
};

export default WalletLoginPage;
