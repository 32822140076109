import React from 'react';
import { Box, Text, Button, HStack, VStack, Icon } from '@chakra-ui/react';
import { FaTrophy, FaGift, FaUsers, FaDollarSign } from 'react-icons/fa';

const RewardCard = ({ reward, onCollect, isCollecting }) => {
    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p="6"
            boxShadow="md"
            bg={reward.index % 2 === 0 ? 'teal.50' : 'gray.50'}
            _hover={{ boxShadow: "xl" }}
        >
            <VStack align="start" spacing="4" w="full">
                <HStack spacing={2} w="full">
                    <Icon as={FaGift} boxSize={6} color="teal.500" />
                    <Text fontSize="xl" fontWeight="bold">{reward.title}</Text>
                </HStack>

                <HStack spacing={2}>
                    <Icon as={FaDollarSign} color="green.500" />
                    <Text>Reward Amount:</Text>
                    <HStack spacing={1}>
                        <img src="/polygon-matic-logo.png" alt="MATIC" style={{ width: '16px', height: '16px' }} />
                        <Text>{reward.reward_amount}</Text>
                    </HStack>
                </HStack>

                <HStack spacing={2}>
                    <Icon as={FaTrophy} color="orange.500" />
                    <Text>Trophies Required:</Text>
                    <Text>{reward.trophies_requirement}</Text>
                </HStack>

                <HStack spacing={2}>
                    <Icon as={FaUsers} color="blue.500" />
                    <Text>Tournaments Required:</Text>
                    <Text>{reward.tournaments_requirement}</Text>
                </HStack>

                <Button
                    colorScheme="teal"
                    onClick={() => onCollect(reward.id)}
                    isLoading={isCollecting}
                    w="full"
                >
                    Collect
                </Button>
            </VStack>
        </Box>
    );
};

export default RewardCard;
