import React from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';

function LoadingPopup({ message }) {
    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            bg="rgba(0, 0, 0, 0.8)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="9999"
        >
            <Box textAlign="center" color="white">
                <Spinner size="xl" thickness="4px" />
                <Text mt="4" fontSize="xl">{message}</Text>
            </Box>
        </Box>
    );
}

export default LoadingPopup;

