import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Layout from './components/Layout';

const HomePage = () => {
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            navigate('/rules');
        }
        else
        {
            navigate('/profile');
        }

    }, [navigate]);

    if (error) return <p>{error}</p>;
    
    return (
        <Layout>
        <div>
            <h1>Home Page</h1>
            <Link to="/wallet-login">Login with Wallet</Link>
        </div>
        </Layout>
    );
};

export default HomePage;
