import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SimpleGrid, Box, Text, Image, Flex } from '@chakra-ui/react';
import TournamentCard from './TournamentCard';
import Layout from './components/Layout';
import { joinTournament } from './services/blockchainService';
import { connectAndAuthenticate } from './services/blockchainService';
import LoadingPopup from './components/LoadingPopup';
import ErrorPopup from './components/ErrorPopup';

function TournamentsPage() {
    const [tournaments, setTournaments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isJoining, setIsJoining] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const wsRef = useRef(null);

    const connectWebSocket = () => {
        if (wsRef.current) {
            // If a WebSocket connection already exists, return early
            return;
        }

        const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
        const wsHost = window.location.hostname === "localhost" ? "localhost:8000" : window.location.host;
        const ws = new WebSocket(`${wsProtocol}://${wsHost}/ws/tournaments/`);

        wsRef.current = ws;  // Store the WebSocket reference

        ws.onopen = () => {
            console.log('WebSocket connection opened successfully.');
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('WebSocket message received:', data);
            if (data.type === 'tournament_created') {
                setTournaments((prevTournaments) => {
                    const exists = prevTournaments.some(tournament => tournament.id === data.tournament.id);
                    if (!exists) {
                        return [...prevTournaments, data.tournament];
                    }
                    return prevTournaments;
                });
            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket encountered an error:', error);
            setErrorMessage('WebSocket encountered an error. Please try again later.');
            setShowErrorPopup(true);
        };

        ws.onclose = (event) => {
            console.log(`WebSocket connection closed: ${event.code} ${event.reason || ''}`);
            wsRef.current = null;  // Clear the WebSocket reference
            if (event.code !== 1000) {
                console.error('WebSocket connection closed unexpectedly. Reconnecting...');
                setTimeout(connectWebSocket, 5000);
            }
        };
    };

    useEffect(() => {
        // Introduce a delay before the initial WebSocket connection attempt
        const wsTimeout = setTimeout(() => {
            connectWebSocket();
        }, 3000); // Adjust the delay as necessary

        const fetchTournaments = async () => {
            try {
                const response = await axios.get('/api/tournaments/');
                setTournaments(response.data);
                setLoading(false);
            } catch (error) {
                setErrorMessage('Failed to load tournaments');
                setLoading(false);
                setShowErrorPopup(true);
            }
        };

        fetchTournaments();

        // Set up an interval to check for tournament status changes
        const intervalId = setInterval(() => {
            const now = new Date();
            setTournaments(prevTournaments => {
                const updatedTournaments = prevTournaments.map(tournament => {
                    const startTime = new Date(tournament.start_time);
                    const endTime = new Date(tournament.end_time);
                    if (now >= startTime && now <= endTime && !tournament.hasStarted) {
                        // Tournament has just started
                        return { ...tournament, hasStarted: true };
                    } else if (now > endTime) {
                        // Tournament has ended, remove it
                        return null;
                    }
                    return tournament;
                }).filter(tournament => tournament !== null);
                return updatedTournaments;
            });
        }, 1000); // Check every second

        return () => {
            clearInterval(intervalId); // Clear interval on component unmount
            clearTimeout(wsTimeout);
            if (wsRef.current) {
                wsRef.current.close(); // Close the WebSocket connection on component unmount
            }
            console.log('Closing WebSocket connection.');
        };
    }, []);

    const handleJoinTournament = async (tournament) => {
        try {
            setIsJoining(true);

            let token = localStorage.getItem('accessToken');
            if (!token) {
                await connectAndAuthenticate();
                token = localStorage.getItem('accessToken');
            }

            const chain = 'polygon';
            const entryFeeInWei = tournament.entry_cost;
            await joinTournament(chain, tournament.id, entryFeeInWei);

            setIsJoining(false);
            navigate(`/tournament/${tournament.id}`, { state: { tournament_id: tournament.id } });
        } catch (error) {
            setErrorMessage(error.message || 'Failed to join tournament');
            setIsJoining(false);
            setShowErrorPopup(true);
        }
    };

    if (loading) return <Text>Loading...</Text>;

    const now = new Date();
    const waitingToStartTournaments = tournaments.filter(tournament => !tournament.hasStarted && new Date(tournament.start_time) > now);
    const startedTournaments = tournaments.filter(tournament => tournament.hasStarted && new Date(tournament.end_time) > now);

    return (
        <Layout>
            <Box p="6" minHeight="100vh" display="flex" flexDirection="column">
                {waitingToStartTournaments.length > 0 && (
                    <Box mb="8">
                        <Text fontSize="2xl" mb="4" fontWeight="bold" color="teal" textAlign="center">
                            Open tournaments
                        </Text>
                        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing="6">
                            {waitingToStartTournaments.map(tournament => (
                                <TournamentCard
                                    key={tournament.id}
                                    tournament={tournament}
                                    onJoin={() => handleJoinTournament(tournament)}
                                    hasStarted={false}
                                />
                            ))}
                        </SimpleGrid>
                    </Box>
                )}

                {startedTournaments.length > 0 && (
                    <Box mb="8">
                        <Text fontSize="2xl" mb="4" fontWeight="bold" color="teal" textAlign="center">
                            Tournaments in progress
                        </Text>
                        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing="6">
                            {startedTournaments.map(tournament => (
                                <TournamentCard
                                    key={tournament.id}
                                    tournament={tournament}
                                    onJoin={() => handleJoinTournament(tournament)}
                                    hasStarted={true}
                                />
                            ))}
                        </SimpleGrid>
                    </Box>
                )}

                <Box flexGrow="1" />
                
                <Flex justifyContent="center" mt="8">
                    <Image src="/comingsooncat.png" alt="Coming Soon" boxSize="300px" />
                </Flex>
            </Box>
            {isJoining && <LoadingPopup message="Waiting to Join Tournament..." />}
            {showErrorPopup && (
                <ErrorPopup
                    message={errorMessage}
                    onClose={() => setShowErrorPopup(false)}
                />
            )}
        </Layout>
    );
}

export default TournamentsPage;
