import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import RegisterUserPage from './RegisterUserPage';
import WalletLoginPage from './WalletLoginPage';
import CodeChallengePage from './CodeChallengePage';
import TournamentsPage from './TournamentsPage';
import TournamentPage from './TournamentPage';
import ProfilePage from './ProfilePage';
import Header from './Header';
import RulesetPage from './RulesetPage';
import KickOutOfTournamentPage from './KickOutOfTournamentPage';
import RewardPage from './RewardPage'; // Import the RewardPage

function App() {
    const [isConnected, setIsConnected] = useState(false);

    const handleConnectWallet = () => {
        // Logic for connecting wallet
        setIsConnected(true);
    };

    return (
        <Router>
            <Header isConnected={isConnected} onConnectWallet={handleConnectWallet} />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/register" element={<RegisterUserPage />} />
                <Route path="/wallet-login" element={<WalletLoginPage />} />
                <Route path="/challenge/:id" element={<CodeChallengePage />} />
                <Route path="/challenge" element={<CodeChallengePage />} />
                <Route path="/tournaments" element={<TournamentsPage />} />
                <Route path="/tournament/:id" element={<TournamentPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/rules" element={<RulesetPage />} />
                <Route path="/kickout" element={<KickOutOfTournamentPage />} />
                <Route path="/rewards" element={<RewardPage />} /> {/* Add the rewards route */}
            </Routes>
        </Router>
    );
}

export default App;
