import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Text, Flex, Icon, Button, Image } from '@chakra-ui/react';
import { FaClock, FaArrowLeft } from 'react-icons/fa';
import AceEditor from 'react-ace';
import LeavePopUp from './LeavePopUp';
import Layout from './components/Layout';
import RewardPopup from './components/RewardPopup';
import LoadingPopup from './components/LoadingPopup';
import ErrorPopup from './components/ErrorPopup';
import 'ace-builds/src-noconflict/theme-dracula';
import 'ace-builds/src-noconflict/mode-python';

const CodeChallengePage = () => {
    const { id: challengeId } = useParams();
    const [challenge, setChallenge] = useState(null);
    const [tournament, setTournament] = useState(null);
    const [userId, setUserId] = useState('');
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [userCode, setUserCode] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showLeavePopup, setShowLeavePopup] = useState(false);
    const [showRewardPopup, setShowRewardPopup] = useState(false);
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [isWaitingForSolutionVerification, setIsWaitingForSolutionVerification] = useState(false);
    const [challengeHasBeenClosed, setChallengeHasBeenClosed] = useState(false);
    const playerCountWsRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const tournamentId = location.state?.tournament_id;

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            setError('Unauthorized');
            navigate('/kickout');
            return;
        }

        const fetchUserProfile = async () => {
            try {
                const profileResponse = await axios.get(`${window.location.origin}/api/auth/profile/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUserId(profileResponse.data.user_id);
            } catch (error) {
                setError('Failed to load user profile');
                setShowErrorPopup(true);
            }
        };

        fetchUserProfile();
    }, [navigate]);

    useEffect(() => {
        const fetchChallengeAndTournament = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) throw new Error('Unauthorized');

                const challengeResponse = await axios.get(`/api/challenges/${challengeId}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setChallenge(challengeResponse.data);
                setUserCode(challengeResponse.data.placeholder_code || '');

                const tournamentResponse = await axios.get(`/api/tournaments/${tournamentId}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setTournament(tournamentResponse.data);
                updateTimeRemaining(tournamentResponse.data.start_time, tournamentResponse.data.end_time);
                setLoading(false);
            } catch (error) {
                setError('Failed to load challenge or tournament');
                setLoading(false);
                setShowErrorPopup(true);
            }
        };

        fetchChallengeAndTournament();
    }, [challengeId, tournamentId]);

    useEffect(() => {
        if (challenge && tournament && userId) {
            const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
            const wsHost = window.location.hostname === "localhost" ? "localhost:8000" : window.location.host;
            const wsUrl = `${wsProtocol}://${wsHost}/ws/tournament/${tournamentId}/`;

            if (playerCountWsRef.current && playerCountWsRef.current.readyState === WebSocket.OPEN) {
                playerCountWsRef.current.close();
            }

            const ws = new WebSocket(wsUrl);

            ws.onopen = () => {
                console.log('WebSocket connection opened successfully.');
                
                ws.send(JSON.stringify({
                    action: 'join',
                    challenge_id: challenge.id,
                    user_id: userId
                }));
            };

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('WebSocket message received:', data);

                if (data.type === 'challenge_closed' && Number(data.challenge_id) === Number(challengeId)) {
                    console.log('Challenge closed detected for this challenge.');
                    if (isWaitingForSolutionVerification) {
                        setChallengeHasBeenClosed(true);
                    } else {
                        setError('Challenge has been solved by another player');
                        setShowErrorPopup(true);
                        setTimeout(() => {
                            navigate(`/tournament/${tournamentId}`);
                        }, 2000);
                    }
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket encountered an error:', error);
                setError('WebSocket encountered an error. Please try again later.');
                setShowErrorPopup(true);
            };

            ws.onclose = (event) => {
                console.log(`WebSocket connection closed: ${event.code} ${event.reason || ''}`);
            };

            playerCountWsRef.current = ws;

            return () => {
                if (playerCountWsRef.current && playerCountWsRef.current.readyState === WebSocket.OPEN) {
                    playerCountWsRef.current.send(JSON.stringify({
                        action: 'leave',
                        challenge_id: challenge.id,
                        user_id: userId
                    }));
                    playerCountWsRef.current.close();
                }
            };
        }
    }, [challenge, tournament, userId, challengeId, tournamentId, navigate, isWaitingForSolutionVerification]);

    const handleLeaveTournament = () => {
        const token = localStorage.getItem('accessToken');
        axios.post(`/api/tournaments/${tournamentId}/leave/`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(() => {
            navigate('/kickout');
        })
        .catch((error) => {
            console.error('Failed to leave tournament:', error);
        });
    };

    // Listen for visibility change and focus/blur events
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                handleLeaveTournament();
            }
        };

        const handleWindowBlur = () => {
            handleLeaveTournament();
        };

        window.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('blur', handleWindowBlur);

        return () => {
            window.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('blur', handleWindowBlur);
        };
    }, []);

    const handleSubmit = () => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            setError('No access token found');
            setShowErrorPopup(true);
            return;
        }

        if (challenge && challenge.id) {
            setIsWaitingForSolutionVerification(true);
            setShowLoadingPopup(true);
            axios.post(
                `/api/challenges/verify/${challenge.id}/`,
                {
                    code: userCode,
                    tournament_id: tournamentId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setMessage(response.data.message);
                const rewardInMatic = response.data.reward;
                setShowLoadingPopup(false);
                setShowRewardPopup(true);
                setTimeout(() => {
                    navigate(`/tournament/${response.data.tournament_id}`);
                }, 2000);
                setMessage(`Congratulations, you solved the challenge and earned ${rewardInMatic} MATIC!`);
            })
            .catch((error) => {
                setShowLoadingPopup(false);
                setIsWaitingForSolutionVerification(false);

                let errorMessage = error.response?.data?.error || 'Failed to verify the solution';
                if (challengeHasBeenClosed) {
                    errorMessage += '. Additionally, the challenge has been closed by another player.';
                }
                
                setError(errorMessage);
                setShowErrorPopup(true);
            });
        } else {
            setError('Challenge ID is missing');
            setShowErrorPopup(true);
        }
    };

    const updateTimeRemaining = (startTime, endTime) => {
        const now = new Date().getTime();
        const start = new Date(startTime).getTime();
        const end = new Date(endTime).getTime();

        let timeLeft;
        if (now < start) {
            timeLeft = start - now;
            setTimeRemaining(timeLeft);
        } else if (now >= start && now <= end) {
            timeLeft = end - now;
            setTimeRemaining(timeLeft);
        } else {
            timeLeft = 0;
            setTimeRemaining(0);
        }
        return timeLeft;
    };

    const renderTimeRemaining = () => {
        if (timeRemaining === null) return null;
        const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
        const seconds = Math.floor((timeRemaining / 1000) % 60);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleBack = () => {
        navigate(`/tournament/${tournamentId}`);
    };

    if (showLeavePopup) {
        return <LeavePopUp tournamentId={tournamentId} onConfirmLeave={() => setShowLeavePopup(false)} />;
    }

    if (loading) return <p>Loading...</p>;

    return (
        <Layout>
            <Box p="6" position="relative">
                <Flex alignItems="center" mb="6">
                    <Text fontSize="3xl" mr="4">Challenge: {challenge?.title}</Text>
                    <Flex alignItems="center">
                        <Icon as={FaClock} boxSize={6} mr="2" color="blue.500" />
                        <Text fontSize="xl" color="blue.500">{renderTimeRemaining()}</Text>
                    </Flex>
                </Flex>
                <Text
                    mb="4"
                    pr="80px"
                    maxW="calc(100% - 180px)"
                >
                    {challenge?.description}
                </Text>
                <Box position="relative">
                    <AceEditor
                        mode="python"
                        theme="dracula"
                        name="code-editor"
                        onChange={setUserCode}
                        value={userCode}
                        placeholder={challenge?.placeholder_code || ''}
                        editorProps={{ $blockScrolling: true }}
                        width="100%"
                        height="400px"
                    />
                    <Image
                        src="/catPilot.png"
                        alt="Cat Pilot"
                        position="absolute"
                        top="-150px"
                        right="20px"
                        boxSize="150px"
                    />
                </Box>
                <Flex justifyContent="center" mt="4">
                    <Button onClick={handleBack} leftIcon={<FaArrowLeft />} colorScheme="teal" variant="outline" mr="8">
                        Back
                    </Button>
                    <Button onClick={handleSubmit} colorScheme="teal">
                        Submit
                    </Button>
                </Flex>
                {message && <Text mt="4" color="blue.500">{message}</Text>}

                {showRewardPopup && (
                    <RewardPopup
                        message="Congratulations, you solved the challenge!"
                        onClose={() => {
                            setShowRewardPopup(false);
                            navigate(`/tournament/${tournamentId}`);
                        }}
                    />
                )}

                {showLoadingPopup && <LoadingPopup message="Checking your solution..." />}

                {showErrorPopup && (
                    <ErrorPopup
                        message={error}
                        onClose={() => {
                            setShowErrorPopup(false);
                            if (error.includes('Challenge has been solved by another player') || timeRemaining === 0) {
                                navigate(`/tournament/${tournamentId}`);
                            }
                        }}
                    />
                )}
            </Box>
        </Layout>
    );
};

export default CodeChallengePage;
