import React from 'react';
import { Box, Button, Text, Image } from '@chakra-ui/react';

function ErrorPopup({ message, onClose }) {
    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            bg="rgba(0, 0, 0, 0.8)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="9999"
        >
            <Box
                bg="white"
                p="6"
                borderRadius="md"
                boxShadow="lg"
                textAlign="center"
                width="300px"
            >
                <Image
                    src="/fail.png"  // Correct path to your fail icon in the public folder
                    boxSize="150px"
                    mx="auto"
                    mb="4"
                />
                <Text mb="4" fontSize="lg" color="red.500">{message}</Text>
                <Button colorScheme="red" onClick={onClose}>
                    Ok
                </Button>
            </Box>
        </Box>
    );
}

export default ErrorPopup;
