import React, { useState } from 'react';
import axios from 'axios';

const RegisterUserPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleRegister = () => {
        axios.post('http://localhost:8000/api/auth/register/', { username, password })
            .then(response => {
                setMessage('User registered successfully');
            })
            .catch(error => {
                setMessage(error.response.data.error || 'Registration failed');
            });
    };

    return (
        <div>
            <h1>Register</h1>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={e => setUsername(e.target.value)}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <button onClick={handleRegister}>Register</button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default RegisterUserPage;

