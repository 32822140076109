import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, Stack, Button, Flex, Icon, Image } from '@chakra-ui/react';
import { FaClock, FaExclamationCircle, FaTrophy, FaGavel } from 'react-icons/fa';
import ChallengeCard from './ChallengeCard';
import LeavePopUp from './LeavePopUp';
import Layout from './components/Layout';
import './index.css';

function TournamentPage() {
    const { id } = useParams();
    const [tournament, setTournament] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [isClosed, setIsClosed] = useState(false);
    const [isStarted, setIsStarted] = useState(false);
    const [showLeavePopup, setShowLeavePopup] = useState(false);
    const [error, setError] = useState(null);
    const [activePlayerCounts, setActivePlayerCounts] = useState({});
    const [results, setResults] = useState([]);
    const wsRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('accessToken');

        if (!token) {
            setError("You are not authorized to view this tournament.");
            return;
        }

        axios.get(`/api/tournaments/${id}/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                setTournament(response.data);
                updateTimeRemaining(response.data.start_time, response.data.end_time);
                fetchActivePlayerCounts(); // Fetch active player counts when tournament is loaded
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    setError("You are not a participant in this tournament.");
                } else if (error.response && error.response.status === 401) {
                    setError("You are not authorized to view this tournament.");
                } else {
                    console.error('Failed to load tournament', error);
                    setError("Failed to load tournament.");
                }
            });
    }, [id]);

    const fetchActivePlayerCounts = () => {
        axios.get(`/api/tournaments/${id}/active-player-counts/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        })
            .then(response => {
                setActivePlayerCounts(response.data);
            })
            .catch(error => {
                console.error('Failed to load active player counts', error);
            });
    };

    const handleLeaveTournament = () => {
        const token = localStorage.getItem('accessToken');
        axios.post(`/api/tournaments/${id}/leave/`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(() => {
                navigate('/kickout');
            })
            .catch((error) => {
                console.error('Failed to leave tournament:', error);
            });
    };

    // Listen for visibility change and focus/blur events
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden && isStarted && !isClosed) {
                handleLeaveTournament();
            }
        };

        const handleWindowBlur = () => {
            if (isStarted && !isClosed) {
                handleLeaveTournament();
            }
        };

        window.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('blur', handleWindowBlur);

        return () => {
            window.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('blur', handleWindowBlur);
        };
    }, [isStarted, isClosed]);

    const fetchTournamentResults = () => {
        axios.get(`/api/tournaments/${id}/results/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        })
            .then(response => {
                setResults(response.data);
            })
            .catch(error => {
                console.error('Failed to load tournament results', error);
            });
    };

    useEffect(() => {
        if (isClosed) {
            fetchTournamentResults();  // Fetch results when tournament is closed
        }
    }, [isClosed]);

    const connectWebSocket = () => {
        const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
        const wsHost = window.location.hostname === "localhost" ? "localhost:8000" : window.location.host;// Define the port where your Django server is running
        const ws = new WebSocket(`${wsProtocol}://${wsHost}/ws/tournament/${id}/`);

        ws.onopen = () => {
            console.log('WebSocket connection opened successfully.');
        };

        ws.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data.type === 'active_player_count_update') {
                    setActivePlayerCounts(prevCounts => ({
                        ...prevCounts,
                        [data.challenge_id]: data.active_players,
                    }));
                } else if (data.type === 'challenge_closed') {
                    setTournament(prevTournament => {
                        if (!prevTournament) return prevTournament;

                        const closedChallenge = prevTournament.open_challenges.find(challenge => challenge.id === data.challenge_id);
                        if (!closedChallenge) return prevTournament;

                        return {
                            ...prevTournament,
                            open_challenges: prevTournament.open_challenges.filter(challenge => challenge.id !== data.challenge_id),
                            closed_challenges: [...prevTournament.closed_challenges, closedChallenge]
                        };
                    });
                }
            } catch (error) {
                console.error('Error parsing WebSocket message data:', error);
            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket encountered an error:', error);
        };

        ws.onclose = (event) => {
            if (event.code !== 1000) { // 1000 means normal closure
                setTimeout(connectWebSocket, 5000);
            }
        };

        wsRef.current = ws;
    };

    useEffect(() => {
        const wsTimeout = setTimeout(() => {
            connectWebSocket();
        }, 1000);

        return () => {
            if (wsRef.current) {
                wsRef.current.close(); // Close the WebSocket connection on component unmount
            }
            clearTimeout(wsTimeout);
        };
    }, [id]);

    useEffect(() => {
        if (tournament && !isClosed) {
            const interval = setInterval(() => {
                updateTimeRemaining(tournament.start_time, tournament.end_time);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [tournament, isClosed]);

    const updateTimeRemaining = (startTime, endTime) => {
        const now = new Date().getTime();
        const start = new Date(startTime).getTime();
        const end = new Date(endTime).getTime();

        if (now < start) {
            setTimeRemaining(start - now);
            setIsStarted(false);
        } else if (now >= start && now <= end) {
            setTimeRemaining(end - now);
            setIsStarted(true);
        } else {
            setTimeRemaining(0);
            setIsStarted(false);
            setIsClosed(true);
        }
    };

    const renderTimeRemaining = () => {
        if (timeRemaining === null || isClosed) return null; // Do not render if the tournament is closed
        const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
        const seconds = Math.floor((timeRemaining / 1000) % 60);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleSolveChallenge = (challengeId) => {
        navigate(`/challenge/${challengeId}`, { state: { tournament_id: id } });
    };

    const handleLeaveTournamentPopup = () => {
        setShowLeavePopup(true);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            setShowLeavePopup(true);
            return (event.returnValue = 'Are you sure you want to leave?');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const waitingTips = [
        {
            icon: FaClock,
            description: 'Waiting for tournament to start.',
        },
        {
            icon: FaExclamationCircle,
            description: 'Once the tournament begins you are not allowed to quit this tab or lose focus.',
        },
        {
            icon: FaExclamationCircle,
            description: 'Doing so will result in your immediate expulsion from the tournament.',
        },
        {
            icon: FaClock,
            description: 'The remaining time in the tournament will always be available on the top of the page.',
        },
        {
            icon: FaGavel,
            description: 'Each challenge shows a description, along with the expected function and sample input and output.',
        },
        {
            icon: FaTrophy,
            description: 'Harder challenges give larger rewards.',
        },
        {
            icon: FaGavel,
            description: 'Once the list of challenges is available, you will always be able to see the current number of participants trying to solve that challenge.',
        },
        {
            icon: FaGavel,
            description: 'Plan accordingly.',
        },
        {
            icon: FaTrophy,
            description: 'Best of luck & let the coding begin!',
        },
    ];

    const renderLogo = () => {
        if (!isStarted && !isClosed) {
            return (
                <Box position="absolute" top="120px" right="100px">
                    <Image src="/cat.png" alt="cat" boxSize="115px" />
                </Box>
            );
        } else if (isStarted && !isClosed) {
            return (
                <>
                    <Box position="absolute" top="120" right="80px">
                        <Image src="/keyboard.png" alt="Keyboard" boxSize="150px" />
                    </Box>
                    <Box position="absolute" top="135" left="320px">
                        <Image src="/nerdycat.png" alt="nerdycat" boxSize="150px" />
                    </Box>
                </>
            );
        } else if (isClosed) {
            return (
                <Box position="absolute" top="45%" right="20%">
                    <Image src="/catspaceship.png" alt="ship" boxSize="415px" className="float" />
                </Box>
            );
        }
        return null;
    };

    const renderChallenges = () => {
        if (!isStarted && timeRemaining > 0) {
            return (
                <Box>
                    <Stack spacing={4}>
                        {waitingTips.map((tip, index) => (
                            <Box key={index} p={4} borderRadius="md" bg="teal.50" shadow="md">
                                <Stack direction="row" align="center">
                                    <Icon as={tip.icon} boxSize={6} color="teal.500" />
                                    <Text>{tip.description}</Text>
                                </Stack>
                            </Box>
                        ))}
                    </Stack>
                </Box>
            );
        } else if (isStarted && !isClosed) {
            // Calculate the reward using the total number of challenges (open + closed)
            const totalChallenges = tournament.open_challenges.length + tournament.closed_challenges.length;
            const challengeReward = tournament.reward_pool / totalChallenges;

            return (
                <Box>
                    <Text fontSize="2xl" mb="4">Open Challenges</Text>
                    <Stack spacing="6">
                        {tournament.open_challenges.map(challenge => (
                            <ChallengeCard
                                key={challenge.id}
                                challenge={challenge}
                                onSolve={() => handleSolveChallenge(challenge.id)}
                                isClosed={false}
                                userCount={activePlayerCounts[challenge.id] || 0}
                                reward={challengeReward} // Set the calculated reward
                            />
                        ))}
                    </Stack>

                    <Text fontSize="2xl" mt="8" mb="4">Closed Challenges</Text>
                    <Stack spacing="6">
                        {tournament.closed_challenges.length > 0 ? (
                            tournament.closed_challenges.map(challenge => (
                                <ChallengeCard
                                    key={challenge.id}
                                    challenge={challenge}
                                    isClosed={true}
                                    userCount={activePlayerCounts[challenge.id] || 0}
                                    reward={challengeReward} // Set the calculated reward
                                />
                            ))
                        ) : (
                            <Text>No challenges have been solved yet.</Text>
                        )}
                    </Stack>
                </Box>
            );
        } else {
            return renderResults();
        }
    };

    const renderResults = () => {
        return (
            <Box>
                <Text fontSize="2xl" mb="4">Tournament Results</Text>
                {results.length > 0 ? (
                    results.map((result, index) => (
                        <Flex
                            key={result.username}
                            alignItems="center"
                            justifyContent="space-between"
                            p={4}
                            borderRadius="md"
                            bg={index === 0 ? "yellow.100" : "gray.50"} // Highlight the top user
                            mb={2}
                            shadow="sm"
                        >
                            <Flex alignItems="center">
                                <Text fontSize="lg" fontWeight="bold" mr={4}>
                                    {`#${index + 1}`}
                                </Text>
                                <Text fontSize="lg" fontWeight="bold">
                                    {result.username}
                                </Text>
                            </Flex>
                            <Box position="relative">
                                <Text
                                    fontSize="m"
                                    fontWeight="bold"
                                    color="black.600"
                                    position="absolute"
                                    top="5"
                                    right="6"
                                    transform="translate(50%, -50%)"
                                >
                                    {result.solved_challenges.length}
                                </Text>
                                <Icon as={FaTrophy} boxSize={12} color="yellow.500" /> {/* Increase boxSize to make the trophy larger */}
                            </Box>
                        </Flex>
                    ))
                ) : (
                    <Text>No participants completed any challenges.</Text>
                )}
            </Box>
        );
    };

    if (showLeavePopup) {
        return <LeavePopUp tournamentId={id} onConfirmLeave={() => setShowLeavePopup(false)} />;
    }

    if (error) {
        return (
            <Box p="6">
                <Text fontSize="xl" color="red.500">{error}</Text>
                <Button onClick={() => navigate('/tournaments')} colorScheme="teal">
                    Back to Tournaments
                </Button>
            </Box>
        );
    }

    if (!tournament) return <Text>Loading...</Text>;

    return (
        <Layout>
            <Box p="6">
                <Flex alignItems="center" mb="6">
                    {!isClosed && (
                        <Flex alignItems="center">
                            <Icon as={FaClock} boxSize={6} mr="2" color="blue.500" />
                            <Text fontSize="xl" color="blue.500">{renderTimeRemaining()}</Text>
                        </Flex>
                    )}
                    {renderLogo()}
                </Flex>
                {renderChallenges()}
            </Box>
        </Layout>
    );
}

export default TournamentPage;
