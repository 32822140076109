import { ethers } from 'ethers';

const getProvider = () => {
    if (!window.ethereum) {
        throw new Error("MetaMask is not installed");
    }

    const provider = new ethers.BrowserProvider(window.ethereum);
    return provider;
};

export const connectWallet = async () => {
    try {
        const provider = getProvider();
        const accounts = await provider.send("eth_requestAccounts", []);
        return accounts[0]; // Return the connected account address
    } catch (error) {
        console.error('Error connecting to wallet:', error);
        throw new Error('Failed to connect wallet');
    }
};

export const signMessage = async (address) => {
    try {
        const provider = getProvider();
        const signer = await provider.getSigner();
        const message = `Sign this message to authenticate with your wallet. Wallet address: ${address}`;
        const signature = await signer.signMessage(message);
        return signature;
    } catch (error) {
        console.error('Error signing message:', error);
        throw new Error('Failed to sign message');
    }
};

export const authenticateWithServer = async (address, signature) => {
    try {
        // Determine the correct base URL
        const baseURL = window.location.protocol + "//" + window.location.host;

        const response = await fetch(`${baseURL}/api/auth/wallet-login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ address, signature }),
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('accessToken', data.access); // Store the JWT token
            localStorage.setItem('refreshToken', data.refresh);
            return true;
        } else {
            throw new Error('Authentication failed');
        }
    } catch (error) {
        console.error('Error authenticating with server:', error);
        throw new Error('Failed to authenticate with server');
    }
};

export const connectAndAuthenticate = async () => {
    try {
        const address = await connectWallet();
        const signature = await signMessage(address);
        const authenticated = await authenticateWithServer(address, signature);

        if (authenticated) {
            localStorage.setItem('userId', address); // Store the user ID (address)
        }

        return address;
    } catch (error) {
        console.error('Error during connect and authenticate process:', error);
        throw error;
    }
};

export const checkBalance = async (address) => {
    try {
        const provider = getProvider();
        const balance = await provider.getBalance(address);
        return ethers.formatUnits(balance, 'ether'); // Convert balance from wei to ether
    } catch (error) {
        console.error('Error fetching balance:', error);
        throw new Error('Failed to fetch balance');
    }
};

export const payEntryFee = async (amountInWei) => {
    try {
        const provider = getProvider();
        await provider.send("eth_requestAccounts", []); // Request MetaMask account access

        const signer = await provider.getSigner(); // Get the signer from MetaMask
        const transaction = {
            to: process.env.REACT_APP_POLYGON_ACCOUNT_ADDRESS,
            value: ethers.parseUnits(amountInWei.toString(), "ether"),
            gasLimit: 21000,
        };

        console.log('Sending transaction:', transaction);

        const tx = await signer.sendTransaction(transaction); // Send the transaction
        await tx.wait(); // Wait for the transaction to be mined
        return tx; // Returning the transaction object, which includes tx.hash
    } catch (error) {
        console.error('Transaction failed:', error);
        throw new Error('Payment failed');
    }
};

export const joinTournament = async (chain, tournamentId, entryFeeInMatic) => {
    try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            throw new Error('No access token found');
        }

        const tx = await payEntryFee(entryFeeInMatic); // tx object returned from payEntryFee

        // Log the transaction hash to verify it's being passed correctly
        console.log('Transaction Hash:', tx.hash);

        // Send the transaction hash to the backend for verification
        const response = await fetch(`/api/tournaments/${tournamentId}/join/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Ensure the token is included here
            },
            body: JSON.stringify({ txHash: tx.hash, chain }), // Send the tx.hash
        });

        if (response.status === 401) {
            // Handle unauthorized case (possibly expired token)
            console.error('Unauthorized: Token may be expired or invalid.');
            // Implement re-authentication or refresh token logic here
            throw new Error('Failed to join the tournament due to authorization issues.');
        }

        if (!response.ok) {
            const responseBody = await response.json();
            console.error('Response from server:', responseBody);
            throw new Error('Failed to join the tournament');
        }

        return response.json();
    } catch (error) {
        console.error('Error during tournament joining process:', error);
        throw error;
    }
};

