import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LeavePopUp = ({ isOpen, onClose, tournament }) => {
  const navigate = useNavigate();

  const handleLeave = async () => {
    try {
      // Close the tournament for the user on the backend
      await axios.post(`/api/tournaments/${tournament.id}/leave/`);
      // Navigate to another page, e.g., home or tournaments list
      navigate('/tournaments');
    } catch (error) {
      console.error('Failed to leave the tournament:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Leave Tournament</ModalHeader>
        <ModalBody>
          <Text>
            Are you sure you want to leave the tournament? Leaving will cause
            you to forfeit and lose any potential rewards.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleLeave}>
            Leave Tournament
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Stay
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LeavePopUp;

