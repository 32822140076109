import React from 'react';
import { Box, Image, Flex, Text, Alert, AlertIcon } from '@chakra-ui/react';
import Layout from './components/Layout'; // Adjust the import path according to your project structure

const KickOutOfTournamentPage = () => {
    return (
        <Layout>
            <Flex justifyContent="center" alignItems="center" height="100vh" position="relative">
                <Box textAlign="center" position="absolute" top="50px" left="50%" transform="translateX(-50%)">
                    <Text fontSize="4xl" fontWeight="bold" color="white" mb={8} textShadow="2px 2px 4px rgba(0, 0, 0, 0.7)">
                        You got Kicked out of the tournament
                    </Text>
                </Box>
                <Box position="absolute" top="180px" right="34%">
                    <Image src="/boot.png" alt="boot" boxSize="400px" />
                </Box>
                <Box position="absolute" top="220px" right="46%">
                    <Image src="/fallingcat.png" alt="cat" mb={8} boxSize="500px" />
                </Box>
                
                <Box position="absolute" bottom="50px" left="50%" transform="translateX(-50%)" width="80%">
                    <Alert status="info" borderRadius="md" boxShadow="lg">
                        <AlertIcon />
                        <Text fontSize="lg" color="gray.800">
                            The most likely reason you got kicked off the tournament is for hiding the tournament or challenge tab or page after the tournament had already started.
                        </Text>
                    </Alert>
                </Box>
            </Flex>
        </Layout>
    );
};

export default KickOutOfTournamentPage;
