import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Heading, Text, Stack, Spinner, Alert, AlertIcon, Image, Select, Flex, Icon } from '@chakra-ui/react';
import { FaTrophy } from 'react-icons/fa';
import Layout from './components/Layout';
import { checkBalance } from './services/blockchainService';
import './index.css'; // Ensure to import your CSS file

const ProfilePage = () => {
    const [profile, setProfile] = useState(null);
    const [balance, setBalance] = useState('');
    const [error, setError] = useState('');
    const [sortOrder, setSortOrder] = useState('date'); // Default sort by date
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            navigate('/wallet-login');
        }

        const apiBaseUrl = window.location.hostname === 'localhost'
            ? 'http://localhost:8000'
            : 'https://cryptochampions.io';

        axios.get(`${apiBaseUrl}/api/auth/profile/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        
        .then(async (response) => {
            setProfile(response.data);
            try {
                const walletBalance = await checkBalance(response.data.wallet_address);
                setBalance(walletBalance);
            } catch (balanceError) {
                console.error('Failed to fetch balance:', balanceError);
                setBalance('Failed to fetch balance');
            }
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                // Token might be expired or invalid
                setError('Session expired. Please log in again.');
                localStorage.removeItem('accessToken');
                navigate('/wallet-login');
            } else {
                setError('Failed to load profile');
            }
        });
    }, [navigate]);

    const sortTournamentHistory = () => {
        if (!profile || !Array.isArray(profile.tournament_history)) return [];

        const sortedHistory = [...profile.tournament_history]; // Make a copy of the tournament history

        if (sortOrder === 'date') {
            // Sort by date (assuming each tournament has a 'date' field)
            sortedHistory.sort((a, b) => new Date(b.date) - new Date(a.date));
        } else if (sortOrder === 'challenges_won') {
            // Sort by challenges won
            sortedHistory.sort((a, b) => b.challenges_won - a.challenges_won);
        }

        return sortedHistory;
    };

    if (error) {
        return (
            <Layout>
                <Alert status="error">
                    <AlertIcon />
                    {error}
                </Alert>
            </Layout>
        );
    }

    if (!profile) {
        return (
            <Layout>
                <Spinner size="xl" />
            </Layout>
        );
    }

    const tournamentHistory = sortTournamentHistory();

    // Calculate the total number of challenges won
    const totalChallengesWon = tournamentHistory.reduce((total, tournament) => total + tournament.challenges_won, 0);

    return (
        <Layout>
            <Box maxW="800px" mx="auto" p={4}>
                <Heading as="h1" mb={6} textAlign="center">
                    Your Profile
                </Heading>
                <Stack spacing={6}>
                    <Box p={4} borderRadius="md" bg="teal.50" shadow="md">
                        <Text fontWeight="bold" fontSize="lg">User ID</Text>
                        <Text mt={2}>{profile.user_id}</Text>
                    </Box>

                    <Box p={4} borderRadius="md" bg="gray.50" shadow="md">
                        <Text fontWeight="bold" fontSize="lg">Wallet Address</Text>
                        <Text mt={2}>{profile.wallet_address}</Text>
                    </Box>

                    <Box p={4} borderRadius="md" bg="teal.50" shadow="md">
                        <Text fontWeight="bold" fontSize="lg">Balance</Text>
                        <Text mt={2}>{balance}</Text>
                    </Box>

                    <Box p={4} borderRadius="md" bg="gray.50" shadow="md">
                        <Flex justifyContent="space-between" alignItems="center" mb={4}>
                            <Text fontWeight="bold" fontSize="lg">Total Challenges Won</Text>
                            <Flex alignItems="center">
                                <Text fontWeight="bold" fontSize="lg" mr={2}>{totalChallengesWon}</Text>
                                <Icon as={FaTrophy} boxSize={6} color="yellow.500" />
                            </Flex>
                        </Flex>
                    </Box>

                    <Box p={4} borderRadius="md" bg="gray.50" shadow="md">
                        <Flex justifyContent="space-between" alignItems="center" mb={4}>
                            <Text fontWeight="bold" fontSize="lg">Tournament History</Text>
                            <Select
                                value={sortOrder}
                                onChange={(e) => setSortOrder(e.target.value)}
                                width="150px"
                            >
                                <option value="date">Order by Date</option>
                                <option value="challenges_won">Order by Challenges Won</option>
                            </Select>
                        </Flex>

                        {tournamentHistory.length > 0 ? (
                            tournamentHistory.map((tournament, index) => (
                                <Flex key={index} justifyContent="space-between" alignItems="center" mb={4}>
                                    <Box>
                                        <Link to={`/tournament/${tournament.id}`}>
                                            <Text fontWeight="bold">Tournament ID: {tournament.id}</Text>
                                        </Link>
                                        <Text>Date: {new Date(tournament.date).toLocaleDateString()}</Text>
                                    </Box>
                                    <Flex alignItems="center">
                                        <Text fontWeight="bold" fontSize="lg" mr={2}>{tournament.challenges_won}</Text>
                                        <Icon as={FaTrophy} boxSize={6} color="yellow.500" />
                                    </Flex>
                                </Flex>
                            ))
                        ) : (
                            <Text>No tournament history available.</Text>
                        )}
                    </Box>
                </Stack>
                <Box position="absolute" top="150" right="12%">
                    <Image
                        src="/bookcastle.png"
                        alt="Website Logo"
                        boxSize="415px"
                    />
                </Box>
                <Box position="absolute" top="95%" left="0%">
                    <Image
                        src="/palmtree.png"
                        alt="Website Logo"
                        boxSize="315px"
                    />
                </Box>
            </Box>
        </Layout>
    );
};

export default ProfilePage;
