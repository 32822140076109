import React, { useEffect, useState } from 'react';
import { Box, Text, Button, Flex, VStack, HStack, Grid, GridItem } from '@chakra-ui/react';
import { FaDollarSign, FaClock, FaTrophy } from 'react-icons/fa';
import UserCountBadge from './UserCountBadge';

const TournamentCard = ({ tournament, onJoin, hasStarted = false }) => {
    const [timeLabel, setTimeLabel] = useState('');
    const [timeRemaining, setTimeRemaining] = useState('');
    const [isNearStart, setIsNearStart] = useState(false);

    useEffect(() => {
        if (!tournament) return;

        const updateTimer = () => {
            const now = new Date().getTime();
            const start = new Date(tournament.start_time).getTime();
            const end = new Date(tournament.end_time).getTime();

            if (!hasStarted && now < start) {
                const timeDiff = start - now;
                const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
                const seconds = Math.floor((timeDiff / (1000)) % 60);
                setTimeLabel('Starts in:');
                setTimeRemaining(`${minutes}:${seconds.toString().padStart(2, '0')}`);
                setIsNearStart(timeDiff < 30000);
            } else if (hasStarted) {
                const timeDiff = end - now;
                const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
                const seconds = Math.floor((timeDiff / (1000)) % 60);
                setTimeLabel('Ends in:');
                setTimeRemaining(`${minutes}:${seconds.toString().padStart(2, '0')}`);
                setIsNearStart(false);
            }
        };

        const interval = setInterval(updateTimer, 1000);
        return () => clearInterval(interval);
    }, [tournament, hasStarted]);

    if (!tournament) {
        return null;
    }

    const getBackgroundColor = () => {
        return 'linear(to-b, pink.100, pink.400)';
        const difficulty = tournament.difficulty?.toLowerCase();
        switch (difficulty) {
            case 'easy':
                return 'linear(to-b, green.200, green.400)';
            case 'medium':
                return 'linear(to-b, yellow.200, yellow.400)';
            case 'hard':
                return 'linear(to-b, red.200, red.400)';
            default:
                return 'linear(to-b, gray.200, gray.400)';
        }
    };

    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p="6"
            boxShadow="md"
            bgGradient={hasStarted ? "linear(to-b, gray.400, gray.600)" : getBackgroundColor()}
            opacity={1}
            _hover={{ boxShadow: "xl" }}
        >
            <VStack align="start" spacing="0.5" w="full">
                <Flex justifyContent="center" alignItems="center" w="full" position="relative" mb="4">
                    <Text fontSize="xl" fontWeight="bold" textAlign="center" color="white" w="full">
                        Code Royale
                    </Text>
                    <Box position="absolute" top="0" right="0">
                        <UserCountBadge count={tournament.num_participants} />
                    </Box>
                </Flex>

                <Grid templateColumns="auto 1fr" gap={2} w="full">
                    <GridItem>
                        <HStack spacing={1}>
                            <FaDollarSign color="green" />
                            <Text color="white">Entry Cost:</Text>
                        </HStack>
                    </GridItem>
                    <GridItem>
                        <HStack spacing={1}>
                            <img src="/polygon-matic-logo.png" alt="MATIC" style={{ width: '16px', height: '16px' }} />
                            <Text color="white">{tournament.entry_cost}</Text>
                        </HStack>
                    </GridItem>

                    <GridItem>
                        <HStack spacing={1}>
                            <FaClock color={isNearStart ? "red" : "lightblue"} className={isNearStart ? "bounce" : ""} />
                            <Text color="white">{timeLabel}</Text>
                        </HStack>
                    </GridItem>
                    <GridItem>
                        <Text color="white">{timeRemaining}</Text>
                    </GridItem>

                    <GridItem mb="6">
                        <HStack spacing={1}>
                            <FaTrophy color="orange" />
                            <Text color="white">Reward Pool:</Text>
                        </HStack>
                    </GridItem>
                    <GridItem mb="6">
                        <HStack spacing={1}>
                            <img src="/polygon-matic-logo.png" alt="MATIC" style={{ width: '16px', height: '16px' }} />
                            <Text color="white">{tournament.reward_pool}</Text>
                        </HStack>
                    </GridItem>
                </Grid>

                <Button
                    colorScheme="teal"
                    onClick={onJoin}
                    isDisabled={hasStarted}
                    w="full"
                    mt="6"
                >
                    {hasStarted ? 'Already Started' : 'Join Tournament'}
                </Button>
            </VStack>

        </Box>
    );
};

export default TournamentCard;
