import React, { useEffect, useState } from 'react';
import { Box, Flex, HStack, Button, Link, Image, Spacer, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import '@fontsource/pacifico'; // Import Pacifico font
import ErrorPopup from './components/ErrorPopup';
import { connectWallet, signMessage, authenticateWithServer } from './services/blockchainService';

const Header = () => {
    const navigate = useNavigate();
    const [isConnected, setIsConnected] = useState(false);
    const [address, setAddress] = useState(null);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        const storedAddress = localStorage.getItem('walletAddress'); // Get the stored wallet address
        if (token) {
            verifyToken(token, storedAddress); // Pass the stored address for validation
        }

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', handleAccountsChanged);
        }
    }, []);

    const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
            setAddress(null);
            setIsConnected(false);
            localStorage.removeItem('walletAddress');
        } else {
            setAddress(accounts[0]);
            localStorage.setItem('walletAddress', accounts[0]); // Save the new address to localStorage
        }
    };

    const connectAndAuthenticate = async () => {
        try {
            const connectedAddress = await connectWallet();
            setAddress(connectedAddress);
            localStorage.setItem('walletAddress', connectedAddress); // Save the address to localStorage
            const signature = await signMessage(connectedAddress);
            await authenticateWithServer(connectedAddress, signature);
            setIsConnected(true);
            navigate('/profile');
        } catch (error) {
            console.error(error.message);
            setErrorMessage(error.message);
            setShowErrorPopup(true);
        }
    };

    const verifyToken = async (token, storedAddress) => {
        try {
            // Determine the correct base URL
            const baseURL = window.location.protocol + "//" + window.location.host;
            
            const response = await fetch(`${baseURL}/api/auth/check-auth/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setIsConnected(true);
                setAddress(storedAddress); // Restore the address from localStorage
            } else {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('walletAddress'); // Clear the wallet address from localStorage
                setIsConnected(false);
            }
        } catch (error) {
            console.error('Error verifying token:', error);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('walletAddress'); // Clear the wallet address from localStorage
            setIsConnected(false);
        }
    };

    return (
        <Box bg="cyan.800" color="white" px={4} py={2}>
            <Flex alignItems="center">
                <Image src="/logo.png" alt="Website Logo" boxSize="100px" onClick={() => navigate('/')} cursor="pointer" />
                <Text
                    fontFamily="'Pacifico', cursive"
                    fontSize="3xl"
                    fontWeight="bold"
                    letterSpacing="wider"
                    cursor="pointer"
                    ml={8}
                    onClick={() => navigate('/rules')}
                >
                    Crypto Champions
                </Text>
                <HStack spacing={8} ml={12}>
                    <Link onClick={() => navigate('/tournaments')}>Tournaments</Link>
                    <Link onClick={() => navigate('/rules')}>Rules</Link>
                    <Link onClick={() => navigate('/rewards')}>Rewards</Link> {/* Added Rewards Link */}
                </HStack>

                <Spacer />

                <HStack spacing={4}>
                    <Button colorScheme="white" onClick={() => alert('Polygon Selected')} variant="outline">
                        Polygon
                    </Button>
                    {isConnected ? (
                        <Button colorScheme="teal" onClick={() => navigate('/profile')}>
                            Profile
                        </Button>
                    ) : (
                        <Button colorScheme="teal" onClick={connectAndAuthenticate}>
                            Connect Wallet
                        </Button>
                    )}
                </HStack>
            </Flex>

            {showErrorPopup && (
                <ErrorPopup
                    message={errorMessage}
                    onClose={() => setShowErrorPopup(false)}
                />
            )}
        </Box>
    );
};

export default Header;
