import React from 'react';
import { Box, Text, Button, VStack, HStack } from '@chakra-ui/react';
import UserCountBadge from './UserCountBadge';

const ChallengeCard = ({ challenge, onSolve, isClosed, userCount, reward }) => {
    return (
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p="6" boxShadow="md">
            <VStack align="start" spacing="4">
                <HStack justifyContent="space-between" w="full">
                    <Text fontSize="xl" fontWeight="bold">
                        {challenge.title}
                    </Text>
                    <UserCountBadge count={userCount} />
                </HStack>
                <Text>{challenge.description}</Text>
                <HStack spacing={1}>
                    <img src="/polygon-matic-logo.png" alt="MATIC" style={{ width: '16px', height: '16px' }} />
                    <Text fontSize="lg" color="teal.500">
                        {reward}
                    </Text>
                </HStack>
                {!isClosed && (
                    <Button colorScheme="teal" onClick={onSolve} w="full">
                        Solve
                    </Button>
                )}
            </VStack>
        </Box>
    );
};

export default ChallengeCard;
